@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap");

@import url("../node_modules/bootstrap-icons/font/bootstrap-icons.css");

// #fafaf9 - 50
// #f5f5f4 - 100
// #e7e5e4 - 200
// #d6d3d1 - 300 (214, 211, 209)
// #a8a29e - 400 (168, 162, 158)
// #78716c - 500
// #57534e - 600 (87, 83, 78)
// #44403c - 700 (68, 64, 60)
// #292524 - 800
// #1c1917 - 900

:root {
  --font-family: "Courier Prime";

  --bright-color: #f5f5f4;
  --dark-color: #292524;

  --background-color: #e7e5e4;
  --component-background-color: rgba(214, 211, 209, 0.5);
  --border-color: rgba(168, 162, 158, 0.5);
  --text-color: #292524;
  --lighter-text-color: #78716c;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #292524;
    --component-background-color: rgba(68, 64, 60, 0.5);
    --border-color: rgba(87, 83, 78, 0.5);
    --text-color: #f5f5f4;
    --lighter-text-color: #d6d3d1;
  }
}

html {
  font-family: var(--font-family);
  font-size: 1em;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div.newspapers {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);

    div.newspaper {
      display: block;
      text-align: center;

      h4,
      h6 {
        margin: 0.25em;
      }

      img {
        width: 360px;
        height: 144px;
      }
    }
  }
}

a,
a:hover,
a:active {
  color: var(--text-color);
}

.breadcrumbs {
  align-items: center;
  font-size: 1.125rem;

  div.delimiter {
    font-size: 0.875rem;
    padding: 0 10px;
  }

  > div:first-child {
    margin-left: 15px;
  }

  > div:last-child {
    margin-right: 15px;
  }

  .dropdown {
    position: relative;

    min-width: 2rem;
    text-align: center;

    &#year-select,
    &#issue-select {
      div:last-child {
        width: max-content;

        a {
          text-align: center;
        }
      }
    }

    > a:first-child {
      display: block;
      padding: 5px;
    }

    div:last-child {
      font-size: 0.875rem;
      position: absolute;
      background-color: var(--text-color);
      left: 0;
      z-index: 10;
      min-width: 100%;
      max-height: 500px;
      overflow-y: scroll;

      a {
        &.active {
          background-color: var(--lighter-text-color);
        }

        cursor: pointer;
        color: var(--background-color);
        display: block;

        &:hover {
          background-color: var(--component-background-color);
        }

        padding: 5px;
      }
    }
  }
}

.invisible {
  visibility: hidden;
}

nav,
footer {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;

  > div {
    border-width: 0;
    border-color: var(--border-color);
  }

  > div:nth-child(2n) {
    flex-grow: 1;
  }

  > div:nth-child(2n + 1) {
    background-color: var(--component-background-color);
    backdrop-filter: blur(4px);
    border-style: solid;
  }

  > div:first-child {
    border-right-width: 1px;
  }

  > div:last-child {
    border-left-width: 1px;
  }
}

nav {
  top: 0;
  color: var(--text-color);

  > div:first-child {
    display: flex;
  }

  ol {
    display: flex;
  }

  > div:nth-child(2n + 1) {
    border-bottom-width: 1px;
  }
}

footer {
  font-size: 0.875rem;
  bottom: 0;
  color: var(--lighter-text-color);

  > div:nth-child(2n + 1) {
    border-top-width: 1px;
    padding: 8px;
    place-self: end;
  }
}

div#search {
  padding: 0.25rem;
  position: relative;
  display: none;

  input {
    display: block;
    width: 20rem;
    padding: 0.3rem 0.3rem 0.3rem 3rem;
    border-radius: 4px;
    background-color: var(--component-background-color);
    border: 1px solid var(--border-color);

    font-family: var(--font-family);
    font-size: 1rem;
    color: var(--lighter-text-color);

    &::placeholder {
      font-family: var(--font-family);
      font-size: 1rem;
    }
  }

  div:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 1rem;
    display: flex;
    align-items: center;

    .bi-search {
      color: var(--lighter-text-color);
    }
  }
}

nav {
  z-index: 1;
}

#nprogress {
  .bar {
    background: var(--lighter-text-color) !important;

    .peg {
      box-shadow: 0 0 10px var(--lighter-text-color),
        0 0 5px var(--lighter-text-color);
    }
  }

  .spinner {
    .spinner-icon {
      border-left-color: var(--lighter-text-color);
      border-top-color: var(--lighter-text-color);
    }
  }
}

#newspaper {
  text-align: center;
  display: flex;
  perspective: 1000px;

  img.preview {
    background: url(data-url:./spinner.svg) no-repeat center,
      var(--background-color);
  }

  div.corner-icon {
    display: none;
  }

  .newspaper__page {
    position: relative;
    width: 50%;
    height: 100%;
    display: grid;
    transform-origin: 0% 0%;
    padding: 0;

    &--0 {
      &.clickable {
        cursor: pointer;
      }

      display: none;
    }

    &--1 {
      &.clickable {
        cursor: pointer;
      }

      position: absolute;
      right: 0;
      transform-style: preserve-3d;
      transform: rotateY(0deg);

      &.rotated {
        transform: rotateY(-180deg);
      }

      &.transition {
        transition: transform var(--transition-duration)
          cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &-front {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotateY(0deg) translateZ(0px);
    }

    &-back {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotateY(180deg) translateZ(1px);
    }
  }
}

@media screen and (max-width: 1280px) {
  body.index {
    > div.newspapers {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 960px), screen and (max-height: 720px) {
  body.index {
    > div.newspapers {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

div.suggestions {
  padding: 5px;
  background-color: var(--component-background-color);
  border: 1px solid var(--component-background-color);
  color: var(--lighter-text-color);

  overflow-y: scroll;
  max-height: 489px;

  position: absolute;
  right: 0;
  z-index: 10;
  min-width: 100%;

  a {
    color: var(--bright-color);
    display: block;

    &:hover {
      filter: brightness(200%);
      color: var(--dark-color);
      text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.empty {
    text-align: center;
    padding: 1rem;
  }

  &:not(.empty) {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    a {
      font-weight: bold;
      font-size: 1.5rem;
      height: 120px;
      width: 180px;
      white-space: pre;
    }
  }
}
